import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  LinearProgress,
  Typography,
  Paper,
} from "@mui/material";

export default function TimerControl({
  setIsRunning,
  stopwatchTime,
  goal,
  setGoal,
  handleReset,
  progress,
}) {
  const [points, setPoints] = useState(0);
  const [lastAwardedTime, setLastAwardedTime] = useState(0);
  const [goalError, setGoalError] = useState(false);
  const [goalErrorMessage, setGoalErrorMessage] = useState("");

  useEffect(() => {
    if (progress >= 100) {
      const earnedPoints = Math.floor((stopwatchTime - lastAwardedTime) / 300); // 300 seconds = 5 minutes
      if (earnedPoints > 0) {
        setPoints((prevPoints) => prevPoints + earnedPoints);
        setLastAwardedTime(stopwatchTime);
      }
    }
  }, [progress, stopwatchTime, lastAwardedTime]);

  const handleGoalChange = (event) => {
    setGoal(event.target.value);
  };

  const validateGoal = () => {
    if (goal === "" || isNaN(goal) || goal <= 0) {
      setGoalError(true);
      setGoalErrorMessage("Please enter a valid positive number");
    } else {
      setGoalError(false);
      setGoalErrorMessage("");
    }
  };

  const handleStart = () => {
    validateGoal();
    if (!goalError) {
      setIsRunning(true);
    }
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5vh",
          marginBottom: "20vh",
        }}
      >
        <Paper
          sx={{
            backgroundColor: "#4C5958",
            padding: 2,
            width: "100%", // Adjust width as needed
            maxWidth: "60vw", // Optional: Set a maximum width
          }}
          elevation={3}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{ marginBottom: 1, color: "#C8C9C8" }}
            >
              {`${Math.round(progress)}%`} Complete
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                width: "80%",
                height: "20px", // Increase the height of the progress bar
                borderRadius: "10px", // Add rounded corners
                backgroundColor: "#d3d3d3", // Change the background color
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#8A897C", // Change the color of the progress bar
                },
                marginBottom: 2,
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 2,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
              }}
            >
              <TextField
                label="Set Goal (minutes)"
                type="number"
                value={goal}
                onChange={handleGoalChange}
                onBlur={validateGoal}
                error={goalError}
                helperText={goalErrorMessage}
                sx={{
                  marginBottom: 2,
                  width: "80%", // Adjust width as needed
                  "& .MuiInputBase-root": {
                    color: "#C8C9C8", // Text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "#C8C9C8", // Label color
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C9C8", // Border color
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#C8C9C8", // Border color on hover
                    },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#C8C9C8", // Border color when focused
                    },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#C8C9C8",
                  marginBottom: "2vh",
                  fontFamily: "inherit", // Ensure font matches overall font
                  fontSize: "1.2rem", // Adjust font size as needed
                }}
              >
                Current Session: {points} points
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                marginRight: 1,
                backgroundColor: "#8A897C", // Custom background color
                color: "#C8C9C8", // Custom text color
                "&:hover": {
                  backgroundColor: "#6E6D5E", // Custom hover color
                },
              }}
              onClick={handleStart}
            >
              Start
            </Button>
            <Button
              variant="contained"
              sx={{
                marginRight: 1,
                backgroundColor: "#8A897C", // Custom background color
                color: "#C8C9C8", // Custom text color
                "&:hover": {
                  backgroundColor: "#6E6D5E", // Custom hover color
                },
              }}
              onClick={() => setIsRunning(false)}
            >
              Stop
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#8A897C", // Custom background color
                color: "#C8C9C8", // Custom text color
                "&:hover": {
                  backgroundColor: "#6E6D5E", // Custom hover color
                },
              }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
        </Paper>
      </Box>
    </div>
  );
}
