import React from "react";

export default function HowToPage() {
  return (
    <div
      style={{
        width: "70%",
        margin: "0 auto",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <h1>How to Use This App</h1>
      <p style={{ textAlign: "left" }}>
        This app is designed for two main purposes: to view the time and to help
        manage productive time. While there are plans to introduce different
        clock UI designs in the future, the current focus is on assisting you
        with staying on task and reaching your productivity goals.
      </p>
      <h3>Track Your Productive Time</h3>
      <p style={{ textAlign: "left" }}>
        Under the "Track Time" tab on the clock page, you’ll find a tool to set
        goals and earn points for staying productive. Before starting, enter
        your desired goal in the provided input field. This could be something
        like "Focus for 25 minutes." Once you click "Start," the app will track
        your progress. If you reach 100% of your goal, you’ll be rewarded with 1
        points for every 5 minutes you managed to stay productive.
      </p>
      <h3>Stay on Task and Earn Points</h3>
      <p style={{ textAlign: "left" }}>
        After completing a goal, you can reset the timer and start a new focus
        session. The app keeps track of your points across sessions, motivating
        you to stay consistent. These points serve as a fun incentive to help
        you remain on task while giving yourself breaks after achieving your
        goals.
      </p>{" "}
      <h3>Future Features</h3>{" "}
      <p style={{ textAlign: "left" }}>
        This app is currently in beta, but future updates will include the
        ability to log details about what you worked on during each session.
        This will provide a way to reflect on your progress and celebrate your
        productivity, further encouraging you to stay focused.
      </p>{" "}
    </div>
  );
}
