import React, { useState, useEffect } from "react";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import "./WorldClock.css";

const cities = [
  { name: "Tokyo", timezone: "Asia/Tokyo" },
  { name: "New York City", timezone: "America/New_York" },
  { name: "London", timezone: "Europe/London" },
  { name: "Paris", timezone: "Europe/Paris" },
  { name: "Sydney", timezone: "Australia/Sydney" },
  { name: "Hong Kong", timezone: "Asia/Hong_Kong" },
  { name: "Singapore", timezone: "Asia/Singapore" },
  { name: "Dubai", timezone: "Asia/Dubai" },
  { name: "São Paulo", timezone: "America/Sao_Paulo" },
  { name: "Los Angeles", timezone: "America/Los_Angeles" },
];

const WorldClock = () => {
  const [times, setTimes] = useState({});

  useEffect(() => {
    const updateTimes = () => {
      const newTimes = {};
      cities.forEach((city) => {
        newTimes[city.name] = new Date().toLocaleString("en-US", {
          timeZone: city.timezone,
        });
      });
      setTimes(newTimes);
    };

    updateTimes();
    const intervalId = setInterval(updateTimes, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="main-content">
      <div className="world-clock-wrapper">
        <div className="world-clock-container">
          {cities.map((city) => (
            <div key={city.name} className="city-clock">
              <h3>{city.name}</h3>
              <Clock
                value={new Date(times[city.name])}
                renderNumbers={true}
                size={150}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorldClock;
