import React from "react";

export default function Roadmap() {
  return (
    <div
      style={{
        width: "70%",
        margin: "0 auto",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <h1>Development Roadmap</h1>
      <h3>Current Status</h3>
      <p style={{ textAlign: "left" }}>coming soon</p>
      <h3>Near Future</h3>
      <p style={{ textAlign: "left" }}>coming soon</p>
      <h3>Long Term</h3>
      <p style={{ textAlign: "left" }}>coming soon</p>
      <h3>Ultimate Goal</h3>
      <p style={{ textAlign: "left" }}>coming soon</p>
    </div>
  );
}
