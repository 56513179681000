import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./Topbar.css";

const Topbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: "Clock Page", link: "/" },
    { text: "How To Use", link: "/tutorial" },
    { text: "About", link: "/about" },
    { text: "Focus Tips", link: "/tips" },
    { text: "Dev Roadmap", link: "/roadmap" },
  ];

  return (
    <AppBar position="static" sx={{ backgroundColor: "#283334" }}>
      {" "}
      {/* Updated background color */}
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{
            textDecoration: "none",
            color: "#C8C9C8", // Updated color
            fontFamily: "inherit", // Ensure font matches overall font
            marginRight: "auto", // Pushes the navigation links to the right
            fontSize: "1.5rem", // Adjust font size as needed
          }}
        >
          Chronarc
        </Typography>
        <div className="topbar-nav">
          <ul>
            {menuItems.map((item) => (
              <li key={item.text}>
                <Link to={item.link} style={{ color: "#C8C9C8" }}>
                  {item.text}
                </Link>{" "}
                {/* Updated color */}
              </li>
            ))}
          </ul>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                component={Link}
                to={item.link}
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary={item.text} sx={{ color: "#C8C9C8" }} />{" "}
                {/* Updated color */}
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
