import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import "./App.css";
import Clock from "./components/Clock/Clock";
import Topbar from "./components/Topbar/Topbar";
import About from "./components/About/About";
import Tips from "./components/Tips/Tips";
import Footer from "./components/Footer/Footer";
import HowToPage from "./components/HowToPage/HowToPage";
import Roadmap from "./components/Roadmap/Roadmap";

const TRACKING_ID = "G-30XLMPRG23"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const location = useLocation();

  useEffect(() => {
    // Send pageview event to Google Analytics
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="App">
      <Topbar />
      <Routes>
        <Route path="/" element={<Clock />} />
        <Route path="/tips" element={<Tips />} />
        <Route path="/tutorial" element={<HowToPage />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
