export default function About() {
  return (
    <div
      style={{
        width: "70%",
        margin: "0 auto",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <h1>About This App</h1>
      <p style={{ textAlign: "left" }}>
        I am a solo developer with a passion for creating. I find joy in
        building web apps and sharing them with others. My motivation isn't to
        start a company or make lots of money—it's about the learning experience
        and the hope that someone out there might find value in what I create.
        For me, maintaining "good" focus time has always been a challenge. In a
        world filled with distractions, research shows that avoiding
        interruptions and setting reasonable, uninterrupted focus goals with
        short breaks can dramatically improve productivity. I've seen this
        struggle in my own life and thought it would be useful to build a tool
        to help track and manage focus time. What you see now is just the
        beginning. Like many things, you have to start somewhere. Over time, I
        hope to expand this app into something even more useful, adding tools to
        measure time spent on tasks and track how focused you are. The ultimate
        goal of this app is to prioritize you, the user. It's not designed for
        corporate use or as a micromanagement tool. Instead, it's meant to help
        you organize one of the world's most precious resources—time.
      </p>
    </div>
  );
}
