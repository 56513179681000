import React from "react";
import "./ClockDisplay.css";

const ClockDisplay = ({ timeString }) => {
  return (
    <div className="clock">
      {timeString.map((char, index) => (
        <div key={index} className="number-box">
          {char}
        </div>
      ))}
    </div>
  );
};

export default ClockDisplay;
