export default function Tips() {
  return (
    <div
      style={{
        width: "70%",
        margin: "0 auto",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <div>
        <h1>Improving Productivity</h1>
        <p style={{ textAlign: "left" }}>
          This app was built with a few intentions in mind. While some pages are
          designed to just showcase the current time, the "track time" tab will
          act as a stopwatch. The motivation behind it is not to just track
          time, but will continue to be built to help make great use of one of
          our most important resources - time.
        </p>
        <p style={{ textAlign: "left" }}>
          Setting a timer for focused work is a powerful way to improve
          productivity by creating intentional blocks of time dedicated to
          specific tasks. Begin by determining the optimal duration for your
          focus sessions—typically between 25 to 90 minutes, depending on your
          task complexity and personal attention span. Shorter intervals, like
          25-30 minutes, are ideal for tasks requiring intense concentration,
          while longer sessions can work better for deep work or projects
          needing sustained effort. Pair your timer with clear goals for each
          session, ensuring you know exactly what you want to accomplish before
          starting. When the timer starts, eliminate potential distractions:
          silence notifications, close unnecessary tabs, and communicate your
          availability if working around others. Use a countdown format that
          provides subtle reminders of time remaining without causing
          stress—visual progress bars or periodic updates can help maintain
          focus. After the timer ends, take a deliberate break to recharge;
          stepping away from your workspace, stretching, or practicing
          mindfulness can refresh your mind for the next session. Consistently
          using this approach helps train your brain to work efficiently within
          structured periods, creating a rhythm of focus and rest that enhances
          long-term productivity.
        </p>
        <p style={{ textAlign: "left" }}>
          Over time, using a timer for focused work not only improves
          productivity but also helps build better time management skills.
          Reviewing your completed sessions can provide valuable insights into
          how long tasks actually take versus your initial estimates, allowing
          you to refine your planning. Gradually increase the duration of your
          focus sessions as your ability to concentrate improves, but always
          listen to your energy levels—pushing too far can lead to diminishing
          returns. Incorporating an end-of-day review where you reflect on your
          completed focus sessions can further enhance this process, helping you
          identify patterns and adjust your workflow. Consistency is key; by
          integrating this habit into your daily routine, your timer becomes
          more than just a tool—it becomes a cornerstone of a disciplined,
          effective approach to work.
        </p>
      </div>
    </div>
  );
}
