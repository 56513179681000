import React, { useState, useEffect, useRef } from "react";
import {
  Tab,
  Tabs,
  Box,
  Snackbar,
  Alert,
  Switch,
  FormControlLabel,
} from "@mui/material";
import "./Clock.css";
import WorldClock from "../WorldClock/WorldClock";
import ClockDisplay from "./ClockDisplay/ClockDisplay";
import TimerControl from "./TimerControl/TimerControl";

const Clock = () => {
  const [tabValue, setTabValue] = useState(0);
  const [time, setTime] = useState(new Date());
  const [stopwatchTime, setStopwatchTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [goal, setGoal] = useState(30); // Default goal is 30 minutes
  const [showSuccess, setShowSuccess] = useState(false);
  const [is24Hour, setIs24Hour] = useState(true); // State for time format
  const stopwatchRef = useRef(null);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (isRunning) {
      const startTime = Date.now() - stopwatchTime * 1000;
      const updateStopwatch = () => {
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        setStopwatchTime(elapsedTime);
        stopwatchRef.current = requestAnimationFrame(updateStopwatch);
      };
      stopwatchRef.current = requestAnimationFrame(updateStopwatch);
    } else {
      cancelAnimationFrame(stopwatchRef.current);
    }

    return () => cancelAnimationFrame(stopwatchRef.current);
  }, [isRunning, stopwatchTime]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleToggleChange = (event) => {
    setIs24Hour(event.target.checked);
  };

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let seconds = String(date.getSeconds()).padStart(2, "0");
    let ampm = "";

    if (!is24Hour) {
      ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
    }

    hours = String(hours).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}${ampm ? `${ampm}` : ""}`;
  };

  const formatStopwatchTime = (time) => {
    const hours = String(Math.floor(time / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, "0");
    const seconds = String(time % 60).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const timeString = formatTime(time).split("");
  const stopwatchString = formatStopwatchTime(stopwatchTime).split("");

  const handleReset = () => {
    setStopwatchTime(0);
    setIsRunning(false);
  };

  const progress = Math.min((stopwatchTime / (goal * 60)) * 100, 100);

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
        textColor="inherit"
        indicatorColor="secondary"
        sx={{
          backgroundColor: "#4C5958",
          "& .MuiTabs-flexContainer": {
            height: "50px",
          },
          "& .MuiTab-root": {
            width: "33.33%",
            color: "white",
            fontFamily: "inherit", // Ensure font matches overall font
          },
          "& .Mui-selected": {
            color: "#8A897C", // Secondary color for selected tab
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#8A897C", // Secondary color for tab indicator
          },
        }}
      >
        <Tab label="Current Time" />
        <Tab label="Track Time" />
        <Tab label="World Clocks" />
      </Tabs>

      {tabValue === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
            marginRight: "10vw",
            marginTop: "5vh",
          }}
        >
          <FormControlLabel
            control={
              <Switch checked={is24Hour} onChange={handleToggleChange} />
            }
            label="24 Hour"
            sx={{
              color: "white",
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#8A897C", // Custom color for the checked switch
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "#8A897C", // Custom color for the switch track when checked
              },
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "#3b4f52",
          paddingTop: tabValue === 2 ? "5vh" : "12vh", // Conditional padding
          position: "relative",
        }}
      >
        <Snackbar
          open={showSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            position: "absolute",
            top: "10px",
            width: "100%",
            maxWidth: "60vw",
          }} // Match width to TimerControl Paper
        >
          <Alert
            onClose={handleCloseSuccess}
            severity="success"
            sx={{ width: "100%" }}
          >
            Goal reached! Timer is at 100%.
          </Alert>
        </Snackbar>

        <Box>
          <Box>{tabValue === 2 && <WorldClock />}</Box>
          <Box>
            {tabValue === 0 && <ClockDisplay timeString={timeString} />}
            {tabValue === 1 && <ClockDisplay timeString={stopwatchString} />}
          </Box>
          {tabValue === 1 && (
            <TimerControl
              isRunning={isRunning}
              setIsRunning={setIsRunning}
              stopwatchTime={stopwatchTime}
              setStopwatchTime={setStopwatchTime}
              goal={goal}
              setGoal={setGoal}
              handleReset={handleReset}
              progress={progress}
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Clock;
