import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <p>© 2024 Cronarc. All rights reserved. - Beta v0.1.1</p>
    </div>
  );
};

export default Footer;
